<script setup lang="ts">
import type { SpotDynamicItemContent } from 'types/blocks';

const props = defineProps<{
  spot: SpotDynamicItemContent;
}>();

const root = useRoot();
const culture = root.settings.metadata.culture;

const formattedDate = computed(() => {
  return new Date(props.spot.date).toLocaleDateString(culture, {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });
});
</script>

<template>
  <article class="group relative">
    <HumbleScroll
      animation="fade"
      class="overflow-hidden bg-gray-50"
      inner-class="relative overflow-hidden bg-black/75 aspect-video"
    >
      <figure class="overflow-hidden">
        <BaseImage
          v-if="spot?.media"
          class="aspect-video w-full scale-100 object-cover transition-all duration-300 ease-out group-hover:scale-105"
          :alt="spot.media?.additionalProperties?.alt"
          :src="useCdnBase(spot.media.url)"
          sizes="xs:240 sm:360 md:420 lg:480 xl:520 xxl:550"
          format="webp"
        />
      </figure>
    </HumbleScroll>

    <div
      v-if="spot?.title || spot?.shortDescription"
      class="mt-4 flex flex-col"
    >
      <h2
        v-if="spot?.title"
        class="mb-1 text-h3 font-bold !tracking-normal md:mb-2 lg:text-h4"
      >
        {{ spot.title }}
      </h2>

      <p
        v-if="spot?.date"
        class="mb-2 text-sm"
      >
        {{ formattedDate }}
      </p>

      <p v-if="spot?.shortDescription">
        {{ truncateTextWithEllipsis(spot.shortDescription, 150) }}
      </p>
    </div>
    <BaseLink
      :to="spot?.url"
      class="!absolute inset-0 !h-full !w-full"
      :aria-label="spot?.name"
    />
  </article>
</template>
