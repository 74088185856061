<script setup lang="ts">
import type { SpotDynamicContent } from 'types/blocks';

const props = defineProps<{
  data: SpotDynamicContent;
}>();
</script>

<template>
  <div
    v-if="props.data?.headline || props.data?.description"
    class="md:w-4/12"
  >
    <h2
      v-if="props.data?.headline"
      class="mb-4 md:mb-8"
    >
      {{ props.data.headline }}
    </h2>
    <p v-if="props.data?.description">{{ props.data?.description }}</p>
  </div>
  <div class="mt-8 grid gap-8 sm:grid-cols-2 md:mt-16 lg:grid-cols-3">
    <div
      v-for="(spot, index) in props.data?.spotPicker"
      :key="`spot-${index}`"
    >
      <PartialsSpotDynamicItem :spot="spot" />
    </div>
  </div>
</template>
